import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useMutation } from "@tanstack/react-query";

import Button from "../Components/buttons/Button";
import FormDropzone from "../Components/forms/Dropzone";
import FormElementLabel from "../Components/forms/FormElementLabel";
import FormInput from "../Components/forms/FormInput";
import H3 from "../Components/forms/H3";
import { postCandidateApplication } from "../lib/api";
import { ApplicantInformation } from "../lib/models";
import PrivacyPolicyView from "../Views/PrivacyPolicyView";
import Dialog from "./Dialog";
import FormText from "./forms/FormText";
import ErrorMessage from "./forms/ErrorMessage";
import { isValidEmail } from "../lib/utils";

const ApplicationForm: React.FC<{ jobAdId: string }> = ({ jobAdId }) => {
  const { t } = useTranslation(["ApplicationView", "form", "common"]);
  const [applicantInfo, setApplicantInfo] =
    useState<ApplicantInformation | null>(null);
  const [applicationSuccess, setApplicationSuccess] = useState<boolean>(false);
  const [cvFile, setCvFile] = useState<File>();
  const [videoFile, setVideoFile] = useState<File>();
  const [otherFile, setOtherFile] = useState<File>();
  const [checked, setChecked] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [applicationError, setApplicationError] = useState<boolean>(false);
  const [formIsValid, setFormIsValid] = useState<boolean>(true);
  const [formValidation, setFormValidation] = useState<{
    cv: boolean;
    name: boolean;
    email: boolean;
    phoneNumber: boolean;
  }>({
    cv: true,
    name: true,
    email: true,
    phoneNumber: true,
  });

  const submitApplication = useMutation({
    mutationFn: (body: FormData) => postCandidateApplication(body, jobAdId),
    onSuccess: (response) => {
      if (response.ok) {
        setApplicationSuccess(true);
        setApplicationError(false);
      } else {
        setApplicationError(true);
      }
    },
  });

  const validateForm = () => {
    const newValidationState = {
      cv: Boolean(cvFile),
      name: Boolean(applicantInfo?.name),
      email:
        Boolean(applicantInfo?.email) &&
        !!applicantInfo?.email &&
        isValidEmail(applicantInfo.email),
      phoneNumber: Boolean(applicantInfo?.phoneNumber),
    };
    setFormValidation(newValidationState);
    const formValid = Object.values(newValidationState).every(Boolean);
    setFormIsValid(formValid);
    return formValid;
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const formData = new FormData();
    if (validateForm()) {
      if (applicantInfo) {
        const files = [
          { file: cvFile, fieldName: "cv_file" },
          { file: videoFile, fieldName: "video_cv_file" },
          { file: otherFile, fieldName: "other_document_file" },
        ];
        files.forEach(({ file, fieldName }) => {
          if (file) {
            formData.append(fieldName, file, file.name);
          }
        });
        const applicantInfoFields = {
          name: applicantInfo.name,
          email: applicantInfo.email,
          phone_number: applicantInfo.phoneNumber,
        };
        Object.entries(applicantInfoFields).forEach(([key, value]) => {
          formData.append(key, value);
        });
        setApplicationSuccess(false);
        submitApplication.mutate(formData);
      }
    }
  };

  return (
    <div className="isolate bg-white px-6 sm:py-10 lg:px-8">
      {applicationSuccess && (
        <div className="text-center">
          {" "}
          <H3 text={t("ApplicationView:success")} />{" "}
        </div>
      )}
      {!applicationSuccess && (
        <>
          <div className="text-center">
            <H3 text={t("ApplicationView:applyForm")} />
          </div>
          <form onSubmit={handleSubmit} className="mx-auto max-w-xl mt-2">
            <div className="grid grid-cols-1 gap-x-8 sm:grid-cols-2">
              <div className="sm:col-span-2">
                <FormInput
                  id={"name"}
                  placeholder={t("form:name")}
                  type="text"
                  errorMessage={
                    !formValidation.name ? t("form:nameError") : undefined
                  }
                  value={applicantInfo?.name ? applicantInfo.name : ""}
                  label={t("form:name")}
                  onChange={(event) =>
                    setApplicantInfo({
                      ...applicantInfo,
                      name: event.target.value,
                    } as ApplicantInformation)
                  }
                  required
                />
              </div>
              <div>
                <FormInput
                  id={"email"}
                  type={"email"}
                  placeholder={t("form:email")}
                  errorMessage={
                    !formValidation.email ? t("form:emailError") : undefined
                  }
                  value={applicantInfo?.email ? applicantInfo.email : ""}
                  label={t("form:email")}
                  onChange={(event) =>
                    setApplicantInfo({
                      ...applicantInfo,
                      email: event.target.value,
                    } as ApplicantInformation)
                  }
                  required
                />
              </div>
              <div>
                <FormInput
                  id={"phoneNumber"}
                  placeholder={t("form:phoneNumber")}
                  value={
                    applicantInfo?.phoneNumber ? applicantInfo.phoneNumber : ""
                  }
                  errorMessage={
                    !formValidation.phoneNumber
                      ? t("form:phoneNumberError")
                      : undefined
                  }
                  label={t("form:phoneNumber")}
                  onChange={(event) =>
                    setApplicantInfo({
                      ...applicantInfo,
                      phoneNumber: event.target.value,
                    } as ApplicantInformation)
                  }
                  required
                />
              </div>
              <div className="sm:col-span-2 mb-3">
                <FormElementLabel label={`${t("form:cv")} ${"*"}`} />
                <FormDropzone type="CV" setFile={setCvFile} />
                <div className="mt-1">
                  {!formValidation.cv && (
                    <ErrorMessage message={t("form:cvError")} />
                  )}
                </div>
              </div>
              <div className="sm:col-span-2 mb-3">
                <FormElementLabel label={t("form:video")} />
                <FormDropzone type="Video" setFile={setVideoFile} />
              </div>
              <div className="sm:col-span-2 mb-3">
                <FormElementLabel label={t("form:other")} />
                <FormDropzone type="Other" setFile={setOtherFile} />
              </div>
            </div>
            <div className="flex h-6 mt-10 mb-3 flex-row items-center">
              <input
                id="comments"
                aria-describedby="comments-description"
                name="comments"
                type="checkbox"
                className="h-4 w-4 mr-4 -mt-5 rounded border-gray-300 text-primary focus:ring-primary"
                checked={checked}
                onChange={() => {
                  setChecked(!checked);
                }}
              />
              <div className="flex flex-col justify-start">
                <FormText text={t("ApplicationView:consent")} />
                <div
                  className="hover: cursor-pointer flex flex-row justify-start space-x-1"
                  onClick={() => setShowDialog(true)}
                >
                  <FormText text={t("ApplicationView:readHere")} bold />
                  <ChevronRightIcon width={16} />
                </div>
              </div>
            </div>
            <div className="mt-10">
              <Button
                type="submit"
                text={t("ApplicationView:apply")}
                onClick={handleSubmit}
                disabled={!checked}
                isLoading={submitApplication.isPending}
              />
              <div className="mt-5 text-center">
                {!formIsValid && (
                  <FormText
                    color={"text-warning"}
                    text={t("form:notValidForm")}
                  />
                )}
                {(submitApplication.error || applicationError) && (
                  <H3
                    color="text-warning"
                    text={t("common:somethingWentWrong")}
                  />
                )}
              </div>
            </div>
          </form>
        </>
      )}
      <Dialog
        show={showDialog}
        onClose={() => setShowDialog(false)}
        content={<PrivacyPolicyView />}
      />
    </div>
  );
};

export default ApplicationForm;
