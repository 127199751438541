import { useTranslation } from "react-i18next";

import H3 from "../Components/forms/H3";

const PrivacyPolicyView: React.FC = () => {
  const { t } = useTranslation("common");
  return (
    <div className="flex flex-col items-center justify-center w-full my-24">
      <H3 text={t("privacyPolicy")} />
      <div className="mx-10 mt-5 xl:w-6/12">
        <div>
          <div>
            <p>
              <strong>
                <span>Privacy</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>Version [2024:1]</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>PITCHLER NOVUS PRIVACY POLICY</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                At&nbsp;Pitchler&nbsp;Novus, privacy is a
                serious&nbsp;matter&nbsp;and we are committed to protecting it.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                In accordance with&nbsp;the General Data Protection Regulation
                (&ldquo;GDPR&rdquo;), this policy explains when, why and how
                companies in our group governed by this Regulation:
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>collect personal data about individuals;</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>use this information;</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                disclose this kind of data to&nbsp;others, when&nbsp;this is
                necessary and under certain conditions;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>keep secure and confidential this personal data.</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                This policy may evolve over time so please check the date on
                this page to make sure that you have the latest version.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>This policy was last updated on December 20th, 2020.</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>WHO WE ARE</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                Pitchler&nbsp;Novus AB (hereafter the &ldquo;Group&rdquo;) is
                made up of multiple individual companies. Whenever dealing with
                one of our Group companies (hereafter the
                &ldquo;Company&rdquo;), the &ldquo;controller&rdquo; of your
                personal data will be the Company that decides why and how your
                personal data is processed.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                Where this policy refers to
                &ldquo;we&rdquo;,&nbsp;&ldquo;our&rdquo; or &ldquo;us&rdquo;
                below, unless mentioned otherwise, this refers to
                the&nbsp;particular Company&nbsp;that is the controller of your
                personal data:
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <em>
                <span>PITCHLER NOVUS AB, c/o LEAPS Studios,&nbsp;</span>
              </em>
              Sandg&auml;rdsgatan&nbsp;12B, 352 30 V&Auml;XJ&Ouml;SWEDEN
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <a
                href="mailto:privacy@pitchler.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                <u>
                  <em>
                    <span>privacy@pitchler.com</span>
                  </em>
                </u>
              </a>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>HOW AND WHAT PERSONNAL INFORMATION IS COLLECTED</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                We may collect and process the following personal data:
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                Personal data you give to us: this is information about you that
                you give to us, entirely voluntarily, by entering information
                via:
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>one of our websites;</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>our mobile applications;</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>social media platforms;</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>corresponding with us by phone, email or otherwise.</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                The information you give us may relate to your identification,
                such as your name, address, email address and phone number, your
                skills and competences, or enquiry details, and may include
                records of any correspondence and responses to any surveys.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                Personal data we collect about you: we may automatically collect
                the following information:
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                details of transactions you carry out through the websites, and
                your visits to our websites, including, but not limited to,
                traffic data, location data, weblogs and other communication
                data, and the resources you access;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                technical information, automatically collected, including
                anonymous data collected by the hosting server for statistical
                purposes, the Internet protocol (IP) address used to connect
                your computer or device to the Internet, browser type and
                version, time zone setting, browser plug-in types and
                versions,&nbsp;operating system&nbsp;and platform. Please see
                cookies for more information;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                any personal data which you allow to be shared that is part of
                your public profile or&nbsp;third party&nbsp;social network.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                Personal data we may receive from other sources: we obtain
                certain personal data about you from sources outside our
                business which may include our Group companies or other
                third-party companies.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                The remaining provisions of this policy also apply to any
                personal data we obtain from these sources.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>WHY AND HOW WE USE YOUR PERSONAL DATA</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>We process your personal data lawfully for:</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                the purpose of handling communication and exchange of
                information with our&nbsp;websites&nbsp;users;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                the purpose of managing our relations; notably when processing
                your application(s), responding to your requests or by regularly
                sending you news and information on our services, our brands or
                our initiatives that might arouse your interest;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                any other specific purpose&nbsp;indicated&nbsp;at the time of
                collecting the information.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                We may use and process your personal data principally:
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                where you have provided freely given and explicit CONSENT and
                for specific,&nbsp;determined&nbsp;and legitimate purposes. You
                may withdraw your consent for us to use your information by
                contacting us at any time.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                where it is necessary for us to pursue our LEGITIMATE INTERESTS
                as a business, provided that your interests or your fundamental
                rights and freedoms are not overridden, taking into
                consideration your reasonable expectations, based on your
                relationship with us.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>For example, for the following purposes:</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                direct marketing activities (other than where we rely on your
                consent) as analysis to improve our marketing strategy, and to
                enhance and&nbsp;personalise&nbsp;your user experience;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                assess and improve our service to customers through recordings
                of any calls with our contact centers;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                detection,&nbsp;prevention&nbsp;and mitigation of fraudulent or
                other illegal activities;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                network and information security in order for us to take steps
                to protect your information against loss or damage, theft
                or&nbsp;unauthorised&nbsp;access.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                where it is necessary for us to COMPLY WITH A LEGAL OBLIGATION
                and notably:
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                to&nbsp;assist&nbsp;a public authority or criminal investigation
                body;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                to&nbsp;identify&nbsp;you when you contact us under certain
                legal circumstances;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>to verify the accuracy of data we hold about you.</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                where it is&nbsp;required&nbsp;for the PERFORMANCE OF A CONTRACT
                we have with you, or because you have asked us to take specific
                steps before&nbsp;entering into&nbsp;a contract.&nbsp;This will
                include information that we process in order to enable you to
                make use of our website and the services we provide through it.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>
                  WHO MAY RECEIVE OR HAVE ACCESS TO YOUR PERSONAL DATA
                </span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>Group companies</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                We may share your information with other Companies within
                the&nbsp;Pitchler&nbsp;Novus group for internal administrative
                purposes or for marketing purposes in connection with the
                products and/or services that complement their own range of
                products and/or services that might interest you.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                We may also transmit your personal data when we redirect your
                request to our contacts within the Group who are able to handle
                your request (for example, to solve a problem you have reported
                to us).
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>Our suppliers and service providers</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                We may disclose your information to our third-party service
                providers, agents, subcontractors and
                other&nbsp;organisations&nbsp;for the purposes of providing
                services to us or directly to you on our behalf. Such third
                parties may include cloud service providers (such as hosting and
                email management) or advertising agencies, administrative
                services, credit reference agencies, credit/debit card payment
                processors, customer satisfaction surveys or other third parties
                who provide services to us and&nbsp;assist&nbsp;us in the
                operation of our business and website.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                When we use third party service providers, we
                only&nbsp;disclose&nbsp;personal data that is necessary for them
                to provide their service and we have a contract in place that
                requires them to keep your information secure and confidential,
                and not to use it other than&nbsp;in accordance with&nbsp;our
                specific instructions.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                Third parties who provide products and services to you through
                us
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                We work closely with various third parties to bring you a range
                of products and services which are complementary to those which
                we provide.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                When you enquire about or&nbsp;purchase&nbsp;one or more of
                these services through us, the relevant third party may use your
                details to provide you with information and to carry out their
                obligations arising from any contracts you have&nbsp;entered
                into&nbsp;with them.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                These third-party providers may share your information with us
                which we will use&nbsp;in accordance with&nbsp;this policy. In
                some cases, they will be acting as a controller of your
                information and therefore we&nbsp;advise you to&nbsp;read their
                own privacy policy.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>Other ways we may share your personal data</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>We may transfer your personal data:</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                to a third party as part of a sale of some or&nbsp;all
                of&nbsp;our business assets to any third party or as part of any
                business restructuring or&nbsp;reorganisation;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                if we are under a duty to disclose or share it in order to
                comply with any legal obligation or when this disclosure is
                required by applicable laws, court orders or government
                regulations, to detect or report a crime, to enforce or apply
                the terms of our contracts or to protect the rights, property or
                safety of our visitors and customers;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                to enforce applicable terms of use, to protect the rights,
                privacy,&nbsp;safety&nbsp;or property of our companies;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>if you have consented to the disclosure.</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                However, we will always take steps to ensure that your privacy
                rights continue to be protected.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>WHERE WE STORE YOUR PERSONAL DATA</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                Your information is stored either in our databases or in the
                databases of our service providers. Mainly because of the
                international dimension of the&nbsp;Pitchler&nbsp;Novus group
                and&nbsp;in order to&nbsp;optimize&nbsp;service quality, the
                information you provide to us may be transferred to countries
                outside the European Economic Area (EEA).
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                In such cases, we will&nbsp;take to&nbsp;the&nbsp;appropriate
                security&nbsp;and confidentiality measures to ensure that your
                privacy rights continue to be protected as outlined in this
                policy. These steps include imposing contractual obligations on
                the recipient of your personal data or ensuring that the
                recipients have subscribed to &ldquo;international
                frameworks&rdquo; that aim to ensure adequate protection.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>HOW LONG WE KEEP YOUR PERSONAL DATA</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                We retain your personal data for&nbsp;a period&nbsp;of time
                necessary to achieve the purpose for which we use that
                information and to fulfil our obligations under other laws.
                Except in specific cases, this period is specified at the time
                the information is collected.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                We do not&nbsp;retain&nbsp;personal data in an identifiable
                format for longer than is necessary.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>WHAT ARE YOUR RIGHTS</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                Subject to applicable law and certain
                exemptions,&nbsp;restrictions&nbsp;or circumstances, you may
                have the right:
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>To access your personal data;</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                To correct,&nbsp;modify&nbsp;and update your personal data;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                To erase your personal data or restrict its processing;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>To withdraw your consent;</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                To object to our use of your personal data for automated
                decisions made about you;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                To ask us to transfer your personal data in a structured data
                file to you or to another service provider if it is technically
                possible (data portability);
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                To lodge a complaint with the competent supervisory authority
                and seek a judicial remedy.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                These rights may be exercised by written request
                sent&nbsp;to&nbsp;the contact details that will be provided to
                you at the time of data collection. A reply will be provided to
                you within a reasonable&nbsp;delay, in accordance with the
                applicable regulations.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>
                  HOW WE ENSURE SECURITY AND CONFIDENTIALITY OF YOUR DATA
                </span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                Our aim is to keep your data as safe and secure as possible at
                all times.&nbsp;Once we have received your personal data, we put
                in place reasonable and&nbsp;appropriate
                technical&nbsp;and&nbsp;organisational&nbsp;measures and
                controls to prevent as much as possible any accidental or
                unlawful destruction, loss, alteration,
                or&nbsp;unauthorised&nbsp;access.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>CONTACT US</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                Please direct any queries about this policy to our Privacy
                Officer using the contact details below:
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <a
                href="mailto:dataprotection@pitchler.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                <u>
                  <em>
                    <span>dataprotection@pitchler.com</span>
                  </em>
                </u>
              </a>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>
                Should you wish to form an official complaint, please contact
                the Data Protection Authority directly depending on the country
                you&nbsp;reside&nbsp;in. You can find their contact details
                here.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>Terms of Services</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>Terms of Service (&ldquo;TOS&rdquo;)</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>Version [2024:1]</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>1 Introduction</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>1.1</span>
              </strong>
              <span>
                &nbsp;Pitchler&nbsp;Novus AB, corporate identity number
                559471-4213, address&nbsp;Sandg&auml;rdsgatan&nbsp;12B, 352 30
                V&auml;xj&ouml;, Sweden with e-mail address&nbsp;
              </span>
              <a
                href="mailto:support@pitchler.se"
                target="_blank"
                rel="noreferrer noopener"
              >
                <u>
                  <span>support@pitchler.se</span>
                </u>
              </a>
              <span>
                &nbsp;(&ldquo;Pitchler&rdquo;) supplies a digital service for
                [facilitating&nbsp;recruitment processes by enabling job seeking
                individuals and recruiting companies to upload videos where they
                introduce themselves and/or job offerings] (the
                &ldquo;Service&rdquo;). The Service is supplied and accessible
                via&nbsp;Pitchler&rsquo;s&nbsp;website&nbsp;
              </span>
              <a
                href="http://www.pitchler.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <u>
                  <span>www.pitchler.com</span>
                </u>
              </a>
              <span>&nbsp;(the &ldquo;Website&rdquo;).</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>1.2</span>
              </strong>
              <span>
                &nbsp;These terms of Service (&ldquo;TOS&rdquo;) govern your use
                of the Service. By ticking the box [&ldquo;I accept the
                TOS&rdquo;] using the registration of a user account for our
                Service, you knowingly accept the TOS and our Privacy Policy in
                full.&nbsp;Accordingly, if you disagree with these TOS or the
                Privacy Policy, or any part thereof, you must not tick the box,
                nor use our Service. You may access our Privacy Policy via this
                link.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>1.3</span>
              </strong>
              <span>
                &nbsp;By accepting these TOS, you&nbsp;warrant&nbsp;and
                represent that you are at least 18 years of age and that you
                have legal capacity, including valid authorization from your
                principal, to sign up for, and use, the Service.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>1.4</span>
              </strong>
              <span>
                &nbsp;Our website uses cookies. By using our website or agreeing
                to the TOS, you consent to our use of cookies&nbsp;in accordance
                with&nbsp;the TOS and our Privacy Policy. You may access our
                Privacy Policy via this link.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>1.5</span>
              </strong>
              <span>
                &nbsp;We kindly ask you to acknowledge and respect that the
                Service is &ndash; for the time being &ndash; only intended to
                serve the territory of Sweden, despite the Service may be
                retrievable from non-Swedish territories. If you are resident
                outside the territory of Sweden, please do not sign up for, or
                otherwise use, the Service.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>2 Intellectual Property Rights</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>2.1</span>
              </strong>
              <span>
                &nbsp;Our Website and the Service may include content and
                material that is subject to intellectual property rights
                protection, such as&nbsp;e.g.&nbsp;copyright and registered
                trademark right(s).
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>2.2</span>
              </strong>
              <span>&nbsp;Subject to the express provisions of these TOS:</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>2.2.1</span>
              </strong>
              <span>
                &nbsp;Pitchler, together with our licensors, own and control all
                the material, including copyright and other intellectual
                property rights in our Service and on the Website; and
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>2.2.2</span>
              </strong>
              <span>
                &nbsp;all the material, including copyright and other
                intellectual property rights in our Services and on
                our&nbsp;Website, are reserved.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>3 License to use the Service</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>3.1</span>
              </strong>
              <span>
                &nbsp;When using the Service, and subject to the other
                provisions of these TOS, you may:
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>3.1.1</span>
              </strong>
              <span>
                &nbsp;view pages from our&nbsp;Website&nbsp;in a web browser;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>3.1.2</span>
              </strong>
              <span>
                &nbsp;download pages from our&nbsp;Website&nbsp;for caching in a
                web browser;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>3.1.3</span>
              </strong>
              <span>&nbsp;print pages from our&nbsp;Website;</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>3.1.4</span>
              </strong>
              <span>
                &nbsp;stream audio and video files from our&nbsp;Website; and
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>3.1.5</span>
              </strong>
              <span>
                &nbsp;use our&nbsp;Website&nbsp;services&nbsp;by means of&nbsp;a
                web browser,
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>3.2</span>
              </strong>
              <span>
                &nbsp;Except as expressly&nbsp;permitted&nbsp;by Section 3.1 or
                the other provisions of these TOS, you must not download any
                material from the Service or capture or save any such
                material&nbsp;to&nbsp;your computer.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>3.3</span>
              </strong>
              <span>
                &nbsp;You may only use the Service, and features of the Service,
                in ways and by means that have been made available
                by&nbsp;Pitchler. This implies that you may not reverse engineer
                or otherwise &lsquo;hack&rsquo; the Service. You must not
                circumvent or otherwise bypass, or&nbsp;attempt&nbsp;to
                circumvent or bypass, any access restriction measures on our
                Service.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>3.4</span>
              </strong>
              <span>
                &nbsp;If you are&nbsp;a representing&nbsp;a business, you may
                use the Service for business purposes. If you are not
                representing a business, you may only use the Service for
                your&nbsp;own,&nbsp;personal purposes.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>3.5</span>
              </strong>
              <span>
                &nbsp;Except as expressly&nbsp;permitted&nbsp;by these TOS, you
                must not edit or otherwise&nbsp;modify&nbsp;any material on our
                Services.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>3.6</span>
              </strong>
              <span>
                &nbsp;Unless you own or control the relevant rights in the
                material, you must not:
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>3.6.1</span>
              </strong>
              <span>
                &nbsp;republish material from our Services or Website (including
                republication on external service);
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>3.6.2</span>
              </strong>
              <span>
                &nbsp;sell, rent or sub-license material from our Service or
                Website;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>3.6.3</span>
              </strong>
              <span>&nbsp;show any material from our Service in public;</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>3.6.4</span>
              </strong>
              <span>
                &nbsp;exploit material from our Service for a commercial
                purpose; or
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>3.6.5</span>
              </strong>
              <span>&nbsp;redistribute material from our Services.</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>3.7</span>
              </strong>
              <span>
                &nbsp;We reserve the right to restrict access to our Service at
                our sole discretion.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>4 Acceptable&nbsp;</span>
              </strong>
              use
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>4.1</span>
              </strong>
              <span>&nbsp;You must not:</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>4.1.1</span>
              </strong>
              <span>
                &nbsp;use the Service in any way or take any action that causes,
                or may cause, damage to the Service or impairment of the
                performance,&nbsp;availability&nbsp;or accessibility of the
                Service;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>4.1.2</span>
              </strong>
              <span>
                &nbsp;use the Service in any way that is unlawful,
                illegal,&nbsp;fraudulent&nbsp;or harmful, or in connection with
                any such purpose or activity;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>4.1.3</span>
              </strong>
              <span>
                &nbsp;use the Service to copy, store, host,&nbsp;transmit, send,
                use, publish or distribute any material which consists of (or is
                linked to) any spyware, computer virus, Trojan horse, worm,
                keystroke logger,&nbsp;rootkit&nbsp;or other malicious computer
                software;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>4.1.4</span>
              </strong>
              <span>
                &nbsp;conduct any systematic or automated data collection
                activities (including without limitation scraping, data mining,
                data extraction and data harvesting) on or in relation to our
                services without our express written consent;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>4.1.5</span>
              </strong>
              <span>
                &nbsp;access or otherwise interact with our Service using any
                robot,&nbsp;spider&nbsp;or other automated means, except for the
                purpose of search engine indexing;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>4.1.6</span>
              </strong>
              <span>
                &nbsp;use data collected from our Service for any direct
                marketing activity (including without limitation email
                marketing, SMS marketing,&nbsp;telemarketing&nbsp;and direct
                mailing).
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>4.2</span>
              </strong>
              <span>
                &nbsp;As&nbsp;part&nbsp;the Service, you may get access to
                contact information&nbsp;to&nbsp;individuals and/or companies.
                You must not use such information, nor any other data collected
                from the Service to contact these individuals, companies or
                other persons or entities outside of the Service unless
                authorized to do so by the individual and/or company in
                question.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>4.3</span>
              </strong>
              <span>
                &nbsp;You must ensure that all the information you supply to us
                through the Service, or in relation to the Services, is
                true,&nbsp;accurate, up to date, current,&nbsp;complete&nbsp;and
                non-misleading.&nbsp;Pitchler&nbsp;does
                not&nbsp;warrant&nbsp;the accuracy and completeness of its
                users.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>5 Registration and accounts</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>5.1</span>
              </strong>
              <span>
                &nbsp;In order to use the Service, you will be requested to
                register for a user account. You do this by completing
                and&nbsp;submitting&nbsp;the account registration form on
                our&nbsp;Website, [and clicking on the verification link in the
                email that will be sent to you.]
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>5.2</span>
              </strong>
              <span>
                &nbsp;You acknowledge that your user account and log-in details
                thereto, including password details, are private, confidential
                information. You must not allow any other person to use your
                account to access the service.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>5.3</span>
              </strong>
              <span>
                &nbsp;If you become aware of any unauthorized disclosure of your
                password or unauthorized use of your user account, you
                must&nbsp;notify us&nbsp;in
                writing&nbsp;immediately&nbsp;to&nbsp;e-mail&nbsp;address set
                out in section 1. 1.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>5.4</span>
              </strong>
              <span>
                &nbsp;You must not use any other person&rsquo;s or
                company&rsquo;s account to access the&nbsp;Service,
                unless&nbsp;you&nbsp;have that&nbsp;their consent.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>5.5</span>
              </strong>
              <span>
                &nbsp;If you register for an account with the Service, you will
                be asked to choose an email address to serve as user ID and a
                password. Where applicable, you will also be asked to provide
                corporate identity information of the Swedish company that
                you&nbsp;represent.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>5.6</span>
              </strong>
              <span>
                &nbsp;Pitchler&nbsp;reserves the right to at their sole
                discretion refuse any user ID
                that&nbsp;Pitchler&nbsp;fear&nbsp;would be
                misleading,&nbsp;defamatory&nbsp;or otherwise in conflict with
                business ethics or applicable laws and/or regulations.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>5.7</span>
              </strong>
              <span>
                &nbsp;You&nbsp;are responsible for&nbsp;any activity performed
                by use of your user account and on the services arising out of
                any failure to keep your password&nbsp;confidential,
                and&nbsp;may be held liable for any losses arising out of such a
                failure.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>6 Cancellation and suspension of account</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>6.1</span>
              </strong>
              <span>
                &nbsp;Pitchler&nbsp;reserves the right to, at their sole
                discretion and without notice or explanation, suspend your user
                account, cancel your user&nbsp;account&nbsp;and/or edit your
                account details.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>6.2</span>
              </strong>
              <span>
                &nbsp;You may cancel your user account on our Website using your
                account control panel or [by sending us an e-mail to the address
                set out in section 1.1.]
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>7 Your content: license</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>7.1</span>
              </strong>
              <span>
                &nbsp;When using the Service, you will, by means of uploading,
                storing, publishing or otherwise transmission, be able to make
                available different types of material, such as for example, yet
                not limited to, text, graphics, images, audio material, video
                material, audio-visual material, scripts, software and files
                (&ldquo;User Content&rdquo;). Any User Content shall be your
                responsibility and property.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>7.2</span>
              </strong>
              <span>
                &nbsp;With regards to User Content, you
                grant&nbsp;Pitchler&nbsp;a worldwide, irrevocable,
                non-exclusive, royalty-free license to reproduce, store and
                publish your content on and in relation to the Service, the
                Website and any successor services and/or websites, without any
                obligation to refer to you as the creator to such User Content.
                This implies that you hereby waive all your moral rights in your
                User Content to the maximum extent&nbsp;permitted&nbsp;by
                applicable law. You also&nbsp;warrant&nbsp;and represent that
                all other moral rights&nbsp;claims&nbsp;to your User Content
                have been waived to the maximum extent&nbsp;permitted&nbsp;by
                applicable law.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>7.3</span>
              </strong>
              <span>
                &nbsp;You also grant&nbsp;Pitchler&nbsp;the right to sub-license
                the rights set out in Section 7.2.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>7.4</span>
              </strong>
              <span>
                &nbsp;You&nbsp;grant to&nbsp;us the right, but not an
                obligation, to bring an action for infringement of the rights
                licensed under Section 7.2.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>7.5</span>
              </strong>
              <span>
                &nbsp;You may edit your User Content to the extent permitted
                using the editing functionality made available on the Service.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>7.6</span>
              </strong>
              <span>
                &nbsp;Without prejudice to our other rights under these TOS, if
                you breach any provision of TOS in any way, or if
                we&nbsp;reasonably suspect&nbsp;that you have breached TOS in
                any way, we may&nbsp;delete, unpublish or edit any or&nbsp;all
                of&nbsp;your User Content and user account.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>7.7</span>
              </strong>
              <span>
                &nbsp;You&nbsp;grant to&nbsp;us the right to perform analysis of
                your User Content and generate and store metadata of the User
                Content. You&nbsp;grant to&nbsp;us the right to process, update
                and edit the User Content where&nbsp;required. The foregoing may
                include the use of&nbsp;third party&nbsp;tools, including those
                hosted externally to the&nbsp;Pitchler&nbsp;Novus AB Service.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>7.8</span>
              </strong>
              <span>
                &nbsp;You grant&nbsp;Pitchler&nbsp;the right to fetch and store
                data from selected social media accounts.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>7.9</span>
              </strong>
              <span>
                &nbsp;You grant&nbsp;Pitchler&nbsp;the right to send or show
                your User Content to other users of the Service, or where you
                opt to publicly share your profile, to non-users of the Service.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>8 Your content: rules</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>8.1</span>
              </strong>
              <span>
                &nbsp;You&nbsp;warrant&nbsp;and represent that your User Content
                will&nbsp;comply with&nbsp;these TOS.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>8.2</span>
              </strong>
              <span>
                &nbsp;You&nbsp;warrant&nbsp;and represent that your User Content
                is not illegal or unlawful, that it does not infringe any
                person&rsquo;s legal rights, and that it is not capable of
                giving rise to legal action against any person (in each case in
                any&nbsp;jurisdiction&nbsp;and under any applicable law).
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>8.3</span>
              </strong>
              <span>
                &nbsp;Your User Content, and&nbsp;Pitchler&rsquo;s&nbsp;use of
                your User Content&nbsp;in accordance with&nbsp;these TOS, must
                not:
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>8.3.1</span>
              </strong>
              <span>&nbsp;be&nbsp;libellous&nbsp;or maliciously false;</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>8.3.2</span>
              </strong>
              <span>&nbsp;be obscene or indecent;</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>8.3.3</span>
              </strong>
              <span>
                &nbsp;infringe any copyright, moral right, database
                right,&nbsp;trade mark&nbsp;right, design right, right in
                passing off, or other intellectual property right;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>8.3.4</span>
              </strong>
              <span>
                &nbsp;infringe any right of confidence, right of privacy or
                right under data protection legislation;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>8.3.5</span>
              </strong>
              <span>
                &nbsp;constitute an incitement to commit a crime, instructions
                for the commission of a crime or the promotion of criminal
                activity;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>8.3.6</span>
              </strong>
              <span>
                &nbsp;be in breach of any contractual obligation owed to any
                person;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>8.3.7</span>
              </strong>
              <span>
                &nbsp;depict violence in an explicit,&nbsp;graphic&nbsp;or
                gratuitous manner;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>8.3.8</span>
              </strong>
              <span>
                &nbsp;be pornographic, lewd,&nbsp;suggestive&nbsp;or sexually
                explicit;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>8.3.9</span>
              </strong>
              <span>
                &nbsp;be untrue, false,&nbsp;inaccurate&nbsp;or misleading;
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>8.3.10</span>
              </strong>
              <span>&nbsp;constitute spam;</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>8.3.11</span>
              </strong>
              <span>
                &nbsp;be offensive, deceptive, fraudulent, threatening, abusive,
                harassing, anti-social, menacing,
                hateful,&nbsp;discriminatory&nbsp;or inflammatory.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>9 Limited warranties</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>9.1</span>
              </strong>
              <span>
                &nbsp;You understand and agree that the Service is supplied to
                you &ldquo;as is&rdquo; and &ldquo;as available&rdquo; without
                express or implied warranty of any kind.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>9.2</span>
              </strong>
              <span>
                &nbsp;To the maximum extent&nbsp;permitted&nbsp;by applicable
                law and subject to Section 10.1, we exclude all representations
                and warranties relating to the Service.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>9.3</span>
              </strong>
              <span>
                &nbsp;Pitchler&nbsp;does not warrant or represent the
                completeness, security, service level or accuracy of the Service
                or any of the information or User Content published on the
                Service or the Website, that the material on the Service and
                Website is up to date, that the Service or Website will remain
                available, or the results or effects from using the Service or
                Website.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>9.4</span>
              </strong>
              <span>
                &nbsp;We reserve the right to&nbsp;discontinue&nbsp;or alter the
                Service and Website and to stop publishing our website, at any
                time in our sole&nbsp;discretion&nbsp;without notice or
                explanation. Save to the extent expressly provided otherwise in
                these TOS, you will not be entitled to any compensation or other
                payment upon the discontinuance or alteration of the Service or
                Website.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>10 Limitations and exclusions of liability</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>10.1</span>
              </strong>
              <span>
                &nbsp;Except for cases of intent or gross
                negligence,&nbsp;Pitchler&nbsp;is not liable for indirect
                damages such as, but not limited to, loss of profit, diminished
                production, loss of business turnover, inability to fulfil
                obligations to third parties, any action taken by third party
                rights holders in connection with their copyrights or other
                intellectual property rights, or loss of benefit from use of the
                Service or any loss of data, including personal data.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>10.2</span>
              </strong>
              <span>
                &nbsp;Notwithstanding the
                above,&nbsp;Pitchler&rsquo;s&nbsp;liability for
                direct&nbsp;damages&nbsp;shall in any event be restricted to
                direct damage that can be considered as typical and foreseeable.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>10.3</span>
              </strong>
              <span>
                &nbsp;You accept that we have an interest in limiting the
                personal liability of our officers and employees and, having
                regard to that interest, you acknowledge that we are a limited
                liability entity (In Sw: Aktiebolag) and you agree that you will
                not bring any claim personally against our officers or employees
                in respect of any losses you suffer in connection with the
                Service or these TOS.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>10.4</span>
              </strong>
              <span>
                &nbsp;Except as otherwise stated in these TOS, you agree to
                indemnify and hold&nbsp;Pitchler&nbsp;harmless against any and
                all costs, damages, losses, and/or liability suffered
                by&nbsp;Pitchler&nbsp;(including reasonable legal fees and
                attorney costs) resulting from your breach of these TOS, the
                Privacy Policy or otherwise from your violation of any law or
                the rights (including intellectual property rights) of a third
                party.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>11 Breaches of these TOS</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>11.1</span>
              </strong>
              <span>
                &nbsp;Without prejudice to our other rights under these TOS, if
                you breach these TOS in any way, or
                if&nbsp;Pitchler&nbsp;reasonably suspect that you have breached
                these TOS in any way, we may send you one or more formal
                warnings, temporarily suspend your access to our services,
                permanently prohibit you from accessing the Service, block
                computers using your IP address from accessing the Service or
                suspend or delete your user account to the Service.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>11.1.1</span>
              </strong>
              <span>
                &nbsp;Where we&nbsp;suspend, or&nbsp;prohibit or block your
                access to our services or a part of our services, you must not
                take any action to circumvent such&nbsp;suspension or
                prohibition&nbsp;or blocking, including without
                limitation,&nbsp;creating&nbsp;and/or using a different account.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>11.2</span>
              </strong>
              <span>
                &nbsp;Nothing set out in these terms may prohibit or otherwise
                limit&nbsp;Pitchler&rsquo;s&nbsp;legal right
                to&nbsp;commence&nbsp;legal action against you, whether for
                breach of contract or otherwise.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>12 Modification of the TOS</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>12.1</span>
              </strong>
              <span>&nbsp;We may revise these TOS from time to time.</span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>12.2</span>
              </strong>
              <span>
                &nbsp;Should we choose to in any way alter these TOS, we will
                give you written notice thereof by written e-mail to your e-mail
                address and posting the changed TOS as a pop up when you log on
                to the Service or&nbsp;visits&nbsp;the Website. The revised TOS
                will apply to the use of the Service starting from the date that
                we give you such notice. If you would not agree to the revised
                TOS, you must stop using the Service.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>12.3</span>
              </strong>
              <span>
                &nbsp;If you have given your express consent to these TOS, we
                will ask for your express consent to any revision of these TOS
                as well; and if you do not provide us with such consent, within
                such period as we may specify, we will disable or delete your
                user account to the Service, and you must stop using the
                Service.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>13 Assignment</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>13.1</span>
              </strong>
              <span>
                &nbsp;You hereby agree that we may assign, transfer,
                sub-contract or otherwise deal with our rights and/or
                obligations under these TOS.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>13.2</span>
              </strong>
              <span>
                &nbsp;You may not without our prior written consent assign,
                transfer, sub-contract or otherwise deal with any of your rights
                and/or obligations under these TOS.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>13.3</span>
              </strong>
              <span>
                &nbsp;If the ownership of&nbsp;Pitchler&rsquo;s&nbsp;business
                changes,&nbsp;Pitchler&nbsp;reserves the right to transfer and
                assign these TOS and any information,&nbsp;rights&nbsp;and
                obligations, including your user account and your User Content,
                that arise from these TOS to another legal entity. This includes
                any assignment to a company
                within&nbsp;Pitchler&rsquo;s&nbsp;group of companies, including
                an owner of, or subsidiary to,&nbsp;Pitchler&nbsp;or to a
                company that is not owned by, or otherwise affiliated
                with&nbsp;Pitchler. Any new legal entity will still have to
                honor the commitments&nbsp;Pitchler&nbsp;have&nbsp;made in these
                TOS.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>14 Severability</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>14.1</span>
              </strong>
              <span>
                &nbsp;If a provision of these TOS is&nbsp;determined&nbsp;by any
                court or other competent authority to be unlawful and/or
                unenforceable, the other provisions will continue in effect.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>14.2</span>
              </strong>
              <span>
                &nbsp;If any unlawful and/or unenforceable provision of these
                TOS would be lawful or enforceable if part of it
                were&nbsp;deleted, that part will be&nbsp;deemed&nbsp;to
                be&nbsp;deleted, and the rest of the provision will continue in
                effect.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>15 Third party rights</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>15.1</span>
              </strong>
              <span>
                &nbsp;A contract under these TOS is for our benefit and
                your&nbsp;benefit, and&nbsp;is not intended to benefit or be
                enforceable by any third party.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>15.2</span>
              </strong>
              <span>
                &nbsp;The exercise of the parties&rsquo; rights under a contract
                under these TOS is not subject to the consent of any third
                party.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>16 Payment</span>
              </strong>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>16.1</span>
              </strong>
              <span>
                &nbsp;From time to time,&nbsp;Pitchler&nbsp;may offer you
                services that are subject to fees. If you&nbsp;purchase&nbsp;any
                such service from&nbsp;Pitchler, you agree to pay us the
                applicable fees and taxes as they are communicated
                by&nbsp;Pitchler&nbsp;on their Website, throughout the
                subscription period or, if applicable, until
                you&nbsp;terminate&nbsp;your subscription, subject to applicable
                notice period. Failure to pay these fees may result in the
                termination of your user account.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>16.2</span>
              </strong>
              <span>
                &nbsp;Your purchase may be subject to foreign exchange fees or
                differences in prices based on location (e.g.&nbsp;exchange
                rates).
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>16.3</span>
              </strong>
              <span>
                &nbsp;You authorize&nbsp;Pitchler&nbsp;to store and continue
                billing your payment method (e.g.&nbsp;credit card) even after
                it has expired, to avoid interruptions in the Service
                (e.g.&nbsp;subscriptions) and to&nbsp;facilitate&nbsp;easy
                payment for new services.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>16.4</span>
              </strong>
              <span>
                &nbsp;Taxes are calculated based on the billing information that
                you provide us at the time of purchase.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>16.5</span>
              </strong>
              <span>
                &nbsp;Pitchler&nbsp;reserves the right to charge interest
                on&nbsp;late payments&nbsp;in accordance with&nbsp;the Swedish
                Interest Act (Swe:&nbsp;R&auml;ntelagen) and to charge fees for
                administering invoices and payment reminders due to&nbsp;late
                payments.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>17 Law and&nbsp;</span>
              </strong>
              jurisdiction
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>17.1</span>
              </strong>
              <span>
                &nbsp;These terms and conditions shall be governed by and
                construed&nbsp;in accordance with&nbsp;laws of Sweden, excluding
                the law (1987:899) of international purchases (the UN Convention
                on Contracts for the International Sale of Goods; CISG) and
                excluding the principles of conflict of laws (international
                private law).
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <span>17.2</span>
              </strong>
              <span>
                &nbsp;Any dispute, controversy or claim arising out of or in
                connection with these TOS, or the
                breach,&nbsp;termination&nbsp;or invalidity thereof, shall be
                finally settled by the Swedish courts, with the District Court
                of V&auml;xj&ouml; as the first instance, unless otherwise
                provided by mandatory law.
              </span>
              <span>&nbsp;</span>
            </p>
          </div>
          <div>
            <p>
              <span>&nbsp;</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyView;
